//import jQuery from "jquery";
//window.$ = window.jQuery = jQuery;
//import angular from 'angular';

//import bootstrap from 'bootstrap';

import copyUrl from './lib/arl.copy';

// https://github.com/aFarkas/lazysizes
import lazySizes from 'lazysizes';

// https://github.com/fregante/object-fit-images
//import objectFitImages from 'object-fit-images';

//import ArlBreakpoints from './lib/arl.breakpoints';

// lazySizes config
lazySizes.cfg.lazyClass = 'js-lazy';
lazySizes.cfg.loadingClass = 'js-lazy-loading';
lazySizes.cfg.loadedClass = 'js-lazy-loaded';
lazySizes.cfg.customMedia = {
    '--breakpoint-sm': '(min-width: 576px)',
    '--breakpoint-md': '(min-width: 768px)',
    '--breakpoint-lg': '(min-width: 992px)',
    '--breakpoint-xl': '(min-width: 1200px)' 
};

//const breakpoints = new ArlBreakpoints(false);