import { setTimeout } from "timers";

class Copy {
    constructor() {
        this.buttons = document.querySelectorAll('.js-copy');
        this.init();
    }

    init() {
        this.buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                const btn = e.currentTarget;
                const input = btn.querySelector('.js-url');
                const message = btn.querySelector('.js-message');
                input.select();
                const copy = document.execCommand('copy');

                if (copy) {
                    message.classList.add('is-active');
                    setTimeout(() => {
                        message.classList.remove('is-active');
                    }, 2000);
                }
            });
        });
    }

}

const copy = new Copy();

export default Copy;